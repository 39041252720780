import { getDiscountedAmount } from "utilities";
import { PickyStoryAddonSelectionMap, PickyStoryClient, PickyStoryDeal, PickyStoryDealTypes, PickyStoryProduct, PickyStoryProductPartial, PickyStorySelectionMap, PickyStoryVariant, PricedPickyStoryAddonSelection } from "./types";
import { ShopifyCheckout, ShopifyProductVariant, ShopifyCheckoutLineItem } from "lib/shopify/storefront-api-client/types/custom.types";

export const fetchDeal = (
  client: PickyStoryClient,
  dealType: PickyStoryDealTypes,
  dealId: string,
  /**
   * client.getLocations currently lazy loads only the deals needed for a given PDP. 
   * By setting this to false the PickyStory Storefront API returns all Deals
   * @source https://developers.pickystory.com/browser-api/api#BrowserApi+getLocations 
   */
  onlyActive: boolean = true
) => {
  if (!client) return null;

  const dealGroups = client.getLocations(dealType, onlyActive);
  if (!dealGroups) return null;

  for (const dealGroup of dealGroups) {
    for (const deal of dealGroup.allDeals) {
      if (deal.id === dealId) return deal;
    }
  }

  return null;
}

enum DISOUNT_TEMPLATE_KEYWORDS {
  COMBO = "PICKY",
  BUNDLE = "BUNDLE"
}

export const isBundleInCart = (checkout?: ShopifyCheckout) => {
  if (!checkout?.discountApplications) return false;

  for (const discount of checkout?.discountApplications) {
    if (
      discount.code?.includes(DISOUNT_TEMPLATE_KEYWORDS.BUNDLE) ||
      discount.code?.includes(DISOUNT_TEMPLATE_KEYWORDS.COMBO)
    ) {
      return discount.applicable;
    }
  }

  return false;
}

export const isBundleLineItem = (lineItem?: ShopifyCheckoutLineItem) => {
  if (!lineItem) return false;
  return lineItem.customAttributes.find(
    attr => attr.key === '_bundle' || attr.key === '_bundleName'
  );
}

export const getBundleNameFromLineItem = (lineItem?: ShopifyCheckoutLineItem) => {
  if (!lineItem) return false;
  const found = lineItem.customAttributes.find(
    attr => attr.key === '_bundle' || attr.key === '_bundleName'
  );

  if (found) return found.value;
}

export const filterAvailablePickyStoryVariants = (dealProduct: PickyStoryProduct, variants: ShopifyProductVariant[]): PickyStoryProductPartial => {
  const properties = {
    position: dealProduct.position,
    id: dealProduct.id,
    variants: [] as PickyStoryVariant[]
  }

  const variantSkus = variants.map(v => v.sku);
  properties.variants = dealProduct.variants
    .filter(v => variantSkus.includes(v.sku))
    .sort((v1, v2) => v1.position > v2.position ? 1 : -1);
  return properties;
}

export const getAddonVariantsFromSelection = (
  addonSelectionMap?: PickyStoryAddonSelectionMap
) => {
  if (!addonSelectionMap) return;
  return Object.keys(addonSelectionMap).map(id => ({
    id: addonSelectionMap[id].id as number,
    quantity: addonSelectionMap[id].quantity || 1,
    price: Number(addonSelectionMap[id].price)
  }))
}

export const getVariantsFromSeletions = (
  deal: PickyStoryDeal,
  selectionMap: PickyStorySelectionMap
) => {
  const selections = Object.keys(selectionMap).map(id => selectionMap[id]);
  const dealVariants = deal.getVariantsForSelections(selections);
      if (!dealVariants) throw new Error('Could not get PickyStory deal variants');

  return dealVariants.map(dealVariant => {
    const dealVariantCopy = {
      id: dealVariant.id,
      position: dealVariant.position,
      properties: {
        '_bundleName': deal.spec.name
      },
      quantity: 1,
      option1: dealVariant.option1,
      option2: dealVariant.option2,
      option3: dealVariant.option3,
      price: dealVariant.price
    }

    const selectionKey = Object.keys(selectionMap).find(key => selectionMap[key].option1 === dealVariantCopy.option1);
    if (selectionKey && selectionMap[selectionKey].option1 === dealVariantCopy.option1) {
      dealVariantCopy.quantity = selectionMap[selectionKey].quantity;
    }

    return dealVariantCopy;
  })
}

export const createSelectionMap = (pickyStoryProducts: PickyStoryProductPartial[]): PickyStorySelectionMap => {
  return pickyStoryProducts.reduce((prev, current) => ({
    ...prev,
    [current.id]: {
      option1: current.variants[0].option1,
      option2: current.variants[0].option2,
      option3: current.variants[0].option3,      
      position: current.position
    }
  }), {})
}

export const createAddonSelectionMap = (
  pickyStoryProducts: PickyStoryProductPartial[]
): PickyStoryAddonSelectionMap => {
  return pickyStoryProducts.reduce((prev, current) => ({
    ...prev,
    [current.id]: {
      id: current.variants[0].id,
      price: current.variants[0].price,
    }
  }), {} as PickyStoryAddonSelectionMap)
}

export const deriveDiscountAmount = (
  deal: PickyStoryDeal,
  subTotal: number | null,
  addonSelectionMap?: PickyStoryAddonSelectionMap
) => {
  if (!subTotal) return 0;
  if (!deal.addonProducts.length) {
    return getDiscountedAmount(
      subTotal,
      deal.discountType === 'fixedAmount' ? 'amount' : 'percentage',
      deal.discountValue
    );
  }

  const addonVariants = getAddonVariantsFromSelection(addonSelectionMap);

  if (addonVariants) {
    return addonVariants.reduce((prev, cur) => {
      return prev + getDiscountedAmount(
        (cur.price * cur.quantity),
        deal.discountType === 'fixedAmount' ? 'amount' : 'percentage',
        deal.discountValue
      )
    }, 0)
  }

  return 0;
}
declare global {
  interface Window {
    ga: {
      getAll: () => { get: (name: string) => string }[]
    };
  }
}

export const isValid = (value: any) => !!value && value !== 'null'

// gets the Google Analytics Client ID
export const getGAClientId = () => {
  try {
    const trackers = window.ga ? window.ga.getAll() : [];
    for (var i = 0; i < trackers.length; i += 1) {
      if (
        trackers[i].get('trackingId') === process.env.GATSBY_GA_TRACKING_CODE
      ) {
        return trackers[i].get('clientId')
      }
    }
  } catch (e) {
    console.error(e)
  }

  return ''
}

export const getRachioAccountEmail = (): string => {
  const rachioAccountEmailStorage = window.sessionStorage.getItem('ajs_email')

  if (rachioAccountEmailStorage) return rachioAccountEmailStorage;

  return '';
}

export const getRachioAccountId = (): string => {
  const rachioAccountId = window.sessionStorage.getItem('ajs_uid')

  if (rachioAccountId) return rachioAccountId;

  return '';
}

export const fetchKeywords = (): string[] => {
  let keywords = new Set<string>();

  const metaTags: NodeListOf<HTMLMetaElement> = document.querySelectorAll('meta[name="keywords"]');

  metaTags.forEach(n => {
    if (n.name === 'keywords') {
      const keywordContent = n.content.split(',') as string[];
      for (const keyword of keywordContent) {
        keywords.add(keyword);
      }
    }
  })

  return Array.from(keywords);
}
// Build a custom products query using the unoptimized version of the SDK
export const COLLECTION_BY_FILTERABLE_FILTERABLE_QUERY = `
  #graphql

  fragment VariantFragment on ProductVariant {
    id
    title
    price {
      amount
      currencyCode
    }
    priceV2: price {
      amount
      currencyCode
    }
    weight
    availableForSale
    sku
    compareAtPrice {
      amount
      currencyCode
    }
    compareAtPriceV2: compareAtPrice {
      amount
      currencyCode
    }
    image {
      id
      src: url
      altText
      width
      height
    }
    selectedOptions {
      name
      value
    }
    unitPrice {
      amount
      currencyCode
    }
    unitPriceMeasurement {
      measuredType
      quantityUnit
      quantityValue
      referenceUnit
      referenceValue
    }
  }

  fragment ProductFragment on Product {
    id
    availableForSale
    createdAt
    updatedAt
    descriptionHtml
    description
    handle
    productType
    title
    vendor
    publishedAt
    onlineStoreUrl
    options {
      name
      values
    }
    images(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          src: url
          altText
          width
          height
        }
      }
    }
    variants(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...VariantFragment
        }
      }
    }
  }

  fragment CollectionFragment on Collection {
    id
    handle
    description
    descriptionHtml
    updatedAt
    title
    image {
      id
      src: url
      altText
    }
  }

  query CollectionByHandleFilterable(
    $handle: String!,
    $first: Int,
    $last: Int, 
    $before: String, 
    $after: String, 
    $sortKey: ProductCollectionSortKeys, 
    $filters: [ProductFilter!]
  ) {
    collection(handle: $handle) {
      ...CollectionFragment
      products(
          first: $first,
          last: $last, 
          before: $before, 
          after: $after, 
          sortKey: $sortKey, 
          filters: $filters,
      ) {
        edges {
          cursor
          node {
            ...ProductFragment
          }
        }
      }
    }
  }
`
import {
  createStorefrontApiClient,
} from '@shopify/storefront-api-client';

export const storefrontAPIClient = createStorefrontApiClient({
  storeDomain: String(process.env.GATSBY_SHOPIFY_CUSTOM_DOMAIN),
  apiVersion: '2024-04',
  publicAccessToken: String(process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN),
  // @ts-ignore ignoring since this will always return the correct type when ran in the browser
  customFetchApi: (url, init) => {
    if(typeof window !== undefined) {
      return fetch(url, init)
    } 
  }
});
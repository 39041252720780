import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Checkbox } from 'components/inputs'
import { below } from 'styles'
import { SanityProductUpsell } from 'types/misc'
import { filterVariantForWFT, getProductsByHandles } from 'lib/shopify'
import { transformShopifyStorefrontImagesToGatsbyImage } from 'utilities'
import { VariantSelector } from '../VariantSelector'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Price } from '../Price'
import { useDiscount } from 'global'
import { ProductUpsellInfoModal } from './ProductUpsellInfoModal'
import { ShopifyProduct, ShopifyProductVariant, GatsbyShopifyStorefrontCopyUpsellProduct } from 'lib/shopify/storefront-api-client/types/custom.types'

type ProductUpsellProps = {
  shopifyStorefrontCopyProduct: GatsbyShopifyStorefrontCopyUpsellProduct
  onChange: (isSelected: boolean, addonItem: { productId: string, variant: ShopifyProductVariant }) => void
  onClick: (selectedProduct: GatsbyShopifyStorefrontCopyUpsellProduct) => void
}

const ProductUpsell = ({
  shopifyStorefrontCopyProduct,
  onClick,
  onChange
}: ProductUpsellProps) => {
  const { getDiscountAmount } = useDiscount();
  const [selectedVariant, setSelectedVariant] = useState(shopifyStorefrontCopyProduct.variants[0]);
  const [isAddonSelected, setIsAddonSelected] = useState(false)
  const upsellPrice = selectedVariant.compareAtPrice 
    ? Number(selectedVariant.compareAtPrice.amount)
    : Number(selectedVariant.price.amount)
  return (
    <>
      <ItemStyles>
        <div className="upsell-content">
          <div className="image-container">
            <div className="input-checkbox">
              <label htmlFor={`${shopifyStorefrontCopyProduct.handle}-checkbox`}>
                <span className="sr-only">
                  {shopifyStorefrontCopyProduct.copy.accessibilityTitle}
                </span>
              
                <Checkbox
                  data-testid='upsell-checkbox'
                  name={`${shopifyStorefrontCopyProduct.handle}-checkbox`}
                  onChange={() => {
                    const updateSelected = !isAddonSelected;
                    setIsAddonSelected(updateSelected);
                    onChange(updateSelected, { 
                      productId: String(shopifyStorefrontCopyProduct.id), 
                      variant: selectedVariant 
                    });
                  }}
                />
              </label>
            </div>
            <div className='image-container'>
              <button onClick={() => onClick(shopifyStorefrontCopyProduct)}>
                <GatsbyImage
                  image={shopifyStorefrontCopyProduct.images[0].gatsbyImageData}
                  alt={shopifyStorefrontCopyProduct.title}
                />
              </button>
            </div>
          </div>
          <div className="heading-content">
            <div>
              <button 
                onClick={() => onClick(shopifyStorefrontCopyProduct)}
                className='underline'
              >
                {shopifyStorefrontCopyProduct.copy.title || shopifyStorefrontCopyProduct.title}
              </button>
              {
                shopifyStorefrontCopyProduct.variants.length > 1 ? (
                  <VariantSelector 
                    variants={shopifyStorefrontCopyProduct.variants}
                    setSelectedVariant={(variant) => {
                      setSelectedVariant(variant)
                      if (isAddonSelected) {
                        onChange(isAddonSelected, { 
                          productId: String(shopifyStorefrontCopyProduct.id), 
                          variant: selectedVariant 
                        });
                      }
                    }}
                    selectedVariant={selectedVariant}
                    hidePrice={true}
                    type='input'
                  />
                ) : null
              }
            </div>
            
            <div className="no-margins h5">
              <span className='sr-only'>Current Price</span>
              <Price 
                basePrice={upsellPrice}
                type="tiny"
                discountAmount={getDiscountAmount(selectedVariant)}
              />
            </div>
          </div>
        </div>
      </ItemStyles>
    </>
  )
}

export type UpsellSelectionItem = {
  variant: ShopifyProductVariant, 
  isSelected: boolean 
}


type ProductUpsellSelectionMap = { 
  [productId: string]: {
    variant: ShopifyProductVariant, 
    isSelected: boolean 
  }
}

export type ProductUpsellSelections = UpsellSelectionItem[];

type Props = { 
  productUpsells: SanityProductUpsell[] 
  title?: string
  onChange: (upsellSelections: ProductUpsellSelections) => void
}
export const ProductUpsellMultiSelect = ({ productUpsells, title, onChange }: Props) => {
  const [shopifyCopyProducts, setShopifyCopyProducts] = useState<GatsbyShopifyStorefrontCopyUpsellProduct[] | null>(null);
  const [upsellSelections, setUpsellSelections] = useState<ProductUpsellSelectionMap | {}>({})
  const [selectedProduct, setSelectedProduct] = useState<GatsbyShopifyStorefrontCopyUpsellProduct | null>(null)
  useEffect(() => {
    const getCartUpsells = async () => {
      try {
        const storefrontShopifyProducts = await getProductsByHandles(
          productUpsells.map(upsell => upsell.product.handle)
        )

        const productUpsellProductsByHandle = productUpsells.reduce((prev, current) => {
          return {
            ...prev,
            [current.product.handle]: current
          }          
        }, {})

        const transformProduct = (shopifyProduct: ShopifyProduct) => {
          if (shopifyProduct?.availableForSale) {
            const variantsFiltered = transformShopifyStorefrontImagesToGatsbyImage({
              ...shopifyProduct,
              variants: filterVariantForWFT(shopifyProduct.variants)
            });

            if (variantsFiltered) {
              return {
                ...variantsFiltered,
                copy: productUpsellProductsByHandle[variantsFiltered.handle]
              }
            }
          }

          return null;
        };

        const transformedProducts = storefrontShopifyProducts
          .map(transformProduct)
          .filter(Boolean) as GatsbyShopifyStorefrontCopyUpsellProduct[];
        
        setShopifyCopyProducts(transformedProducts)
      } catch {
        console.error('Could not fetch Sanity Product Upsells')
      }
    }
    getCartUpsells()
  }, [])

  const onUpsellChange = (
    isSelected: boolean, 
    addonItem: { productId: string, variant: ShopifyProductVariant }
  ) => {
    const updatedUpsellSelections = {
      ...upsellSelections,
      [addonItem.productId]: {
        isSelected,
        variant: addonItem.variant
      }
    }

    onChange(
      Object
        .keys(updatedUpsellSelections)
        .map(key => updatedUpsellSelections[key])
    )
    setUpsellSelections(updatedUpsellSelections)
  }

  return shopifyCopyProducts ? (
    <div className='product-upsell-list'>
      <ProductUpsellInfoModal
        onClose={() => setSelectedProduct(null)}
        isActive={Boolean(selectedProduct)}
        product={selectedProduct}
      />
      <div>
        {title && (
          <h4 className='no-top-margin'>{title}</h4>
        )}
        {shopifyCopyProducts.map(product => (
          <ProductUpsell 
            onClick={setSelectedProduct}
            shopifyStorefrontCopyProduct={product}
            key={product.id}
            onChange={onUpsellChange}
          />
        ))}
      </div>
    </div>
  ) : null;
}

const ItemStyles = styled.div`
  .upsell-content {
    display: flex;
    position: relative;    
    margin: 1rem auto;
    border-radius: var(--cardRadius);
    border: 1px solid var(--lineColor);
    padding: 0 1rem;
    .heading-content {
      width: 100%;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;

      ${below.small`
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 1rem 0;
      `}
    }
  }

  .variant-select-input {
    border-radius: 25px;
    padding: 2px 10px;
    font-size: 13px;
    margin: 0;
    ${below.small`
      margin: .5rem 0;
    `}
  }

  .image-container {
    isolation: isolate;
    border-radius: 1rem;
    overflow: hidden; 
    width: 10rem;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
  }

  .input-checkbox {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
`

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Grid, below } from 'styles'
import { Price } from './Price'
import { useDiscount } from 'global'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getBasePrice, organizeByHandle, ShopifyCopyProduct, transformGatsbyShopifyProduct } from 'utilities'
import styled from 'styled-components'
import { GatsbyShopifyProduct } from 'lib/shopify/storefront-api-client/types/custom.types'

type Props = {
  pathname?: string
}

export function CrossSell({ pathname = '' }: Props) {
  const { getDiscountAmount } = useDiscount()

  const { enclosure, controllers, allSanityProductPage } = useStaticQuery(graphql`
    query CrossSell {
      enclosure: shopifyProduct(handle: { eq: "waterproof-enclosure" }) {
        title
        handle
        variants {
          availableForSale
          title
          price
          sku
        }
        media {
          ... on ShopifyMediaImage {
            id
            image {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [WEBP, AUTO]
              )
              altText
            }
          }
        }
      }

      allSanityProductPage {
        edges {
          node {
            ...sanityProductCopy
          }
        }
      }

      controllers: shopifyCollection(
        handle: { eq: "smart-sprinkler-solutions" }
      ) {
        title
        products {
          handle
          title
          variants {
            availableForSale
            title
            price
            sku
          }
          media {
            ... on ShopifyMediaImage {
              id
              image {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  formats: [WEBP, AUTO]
                )
                altText
              }
            }
          }
        }
      }
    }
  `)

  const copyProductsOrganizedByHandle = organizeByHandle(allSanityProductPage.edges);

  const mergeShopifyProductWithCopyProduct = (product: GatsbyShopifyProduct): ShopifyCopyProduct => {
    const copyProduct = copyProductsOrganizedByHandle[product.handle];
    const formattedShopifyProduct = transformGatsbyShopifyProduct(product);

    return {
      ...formattedShopifyProduct,
      copy: copyProduct || {}
    } as unknown as ShopifyCopyProduct
  };

  const allProducts = [...controllers.products, enclosure]
    .map(mergeShopifyProductWithCopyProduct)
    .filter(Boolean);

  return (
    <Container>
      <h3>Customers Also Buy</h3>
      <Grid
        cols={[1, 1, 4]}
        className="container"
        data-testid="cross-sell"
        id="cross-sell"
      >
        {allProducts
          .filter(
            (product) =>
              product && pathname !== product.copy?.seoPathname
          )
          .map((product: ShopifyCopyProduct) => {
            if (!product) return null;
            const discountAmount = getDiscountAmount(product.variants[0])

            return (
              <Link to={product.copy ? product.copy.seoPathname : ''} key={product.title}>
                <GatsbyImage
                  image={product.images[0].gatsbyImageData}
                  alt={'cross sell'}
                  objectFit="contain"
                />
                <h5>{product.title}</h5>
                <Price 
                  discountAmount={discountAmount} 
                  basePrice={getBasePrice(product.variants[0])} 
                />
              </Link>
            )
          })}
      </Grid>
    </Container>
  )
}

const Container = styled.div`
  .gatsby-image-wrapper [data-main-image] {
    border-radius: var(--cardRadius);
  }

  ${below.small`
    .gatsby-image-wrapper [data-main-image] {
      width: 100%;
      height: auto;
    }
  `}
`

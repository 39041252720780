import React, {
  FC,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react'
import { useStorage } from 'hooks'
import { useCheckout } from 'global'
import { DISOUNT_TEMPLATE_KEYWORDS, checkParams, convertDateToTime, isDateInFuture } from 'utilities'
import { isBundleInCart } from '../lib/pickystory'
import { DISCOUNT_STATUS, fetchDiscountByCode } from '../lib/retail-api'
import { Discount } from 'types/misc'

const initialDiscount: Discount = {
  code: '',
  amount: 0,
  percentage: 0,
  products: [],
  summary: ''
}

export const initialDiscountValues = {
  discount: initialDiscount,
  getDiscountAmount: (_: { price: { amount: string|number }, compareAtPrice?: { amount: string|number } | null, sku: string }) => 0,
  getDiscountedPrice: (_: { price: number, discountAmount: number }) => "",
}

export const DiscountContext = createContext(initialDiscountValues)

export const DiscountProvider: FC = ({ children }) => {
  const { checkout, applyDiscount, getCheckout } = useCheckout()
  const [discount, setDiscount] = useState<Discount>(initialDiscount)
  // ? Used when giving a discount via url link
  // * Set ?discount=[CODE]
  const [discountStorage, setDiscountStorage] = useStorage({
    key: 'discount'
  })

  const isDiscountValid = (discountRes: { status: DISCOUNT_STATUS, endsAt?: string | null }) => (
    discountRes.status === DISCOUNT_STATUS.ACTIVE 
    && (!discountRes.endsAt || isDateInFuture(discountRes.endsAt))
  )

  useEffect(() => {
    checkParams('discount', async (discount) => {
      try {
        if (discountStorage && discountStorage.code === discount) return;
        
        const discountRes = await fetchDiscountByCode(discount);

        if (!isDiscountValid(discountRes)) return;

        const discountTransformed = {
          ...discountRes,
          products: discountRes.variants,
          percentage: discountRes.percentage ? discountRes.percentage * 100 : null,
          isActive: discountRes.status === 'ACTIVE'
        } as Discount;

        setDiscountStorage(
          discountTransformed, 
          convertDateToTime(discountRes.endsAt || 1)
        );
        getCheckout();
      } catch (error) {
        console.error(error)
      }
    })
  }, [location.search]);

  useEffect(() => {
    if (discountStorage) {
      setDiscount(discountStorage);
    }
  }, [discountStorage])

  useEffect(() => {
    if (!checkout || !discount.code) return;

    const discountAlreadyApplied = checkout.discountApplications.find(
      d => {
        if (!discount.code || !d.code) return false;

        if (discount.code.trim() === d.code) return true;

        const highPriorityDiscountApplied = Boolean(Object.values(DISOUNT_TEMPLATE_KEYWORDS).find(c => d.code?.includes(c)));

        if (highPriorityDiscountApplied && d.applicable) return true;
      }
    );

    if (discount.code && !discountAlreadyApplied && !isBundleInCart(checkout)) {
      applyDiscount(discount.code);
    }
  }, [checkout?.lineItems])

  // ? Helper to get the best discount based on price and type
  // * This is used to show a discounted amount for items on the site that use the Price component and are not in your cart yet
  const getDiscountAmount = ({
    price,
    compareAtPrice,
    sku,
  }: {
    price: {
      amount: number | string
    }
    compareAtPrice?: {
      amount: number | string
    } | null
    sku: string
  }): number => {
    const basePrice = Number(price.amount);

    if (compareAtPrice) {
      return Number(compareAtPrice.amount) - basePrice;
    }

    if (!discount.code || discount.code.includes(DISOUNT_TEMPLATE_KEYWORDS.TIERED)) return 0;
    const isDiscounted = discount.products?.find((product) => product.sku === sku);

    if (isDiscounted && discount.amount) {
      return discount.amount;
    }

    if (isDiscounted && discount.percentage) {
      const percentTotal = Number(basePrice) * (discount.percentage / 100);
      return percentTotal;
    }

    return 0;
  }

  const getDiscountedPrice = ({
    price,
    discountAmount
  }: {
    price: number,
    discountAmount: number
  }) => {
    const amount = price - discountAmount;
    return amount.toFixed(2);
  }

  return (
    <DiscountContext.Provider
      value={{
        discount,
        getDiscountAmount,
        getDiscountedPrice
      }}
    >
      {children}
    </DiscountContext.Provider>
  )
}

export const useDiscount = () => useContext(DiscountContext)

import React from 'react'
import styled, { css } from 'styled-components'
import { Grid, below } from 'styles'
import { Price } from './Price'
import { useDiscount } from 'global'
import { getBasePrice } from 'utilities'
import { VariantSelectorType } from 'types/misc'
import { ShopifyProductVariant } from 'lib/shopify/storefront-api-client/types/custom.types'

interface Props {
  type?: VariantSelectorType
  size?: 'normal' | 'small'
  defaultTitle?: string
  hidePrice?: boolean
  variantDescription?: string
  variants: ShopifyProductVariant[]
  setSelectedVariant: (variant: ShopifyProductVariant) => void
  selectedVariant: ShopifyProductVariant
  testId?: string
  // override the default promo discount pricing
  discount?: {
    amount: number,
    percentage: number
  }
}

interface VariantSelectorComponentProps extends Props {
  defaultTitle?: string
  size?: 'normal' | 'small'
  discountedAmount: (
    variant: ShopifyProductVariant,
    discount?: {
      amount: number,
      percentage: number,
    },
    returnPrice?: boolean
  ) => number | string
}
const VariantSelectorInput = ({
  variants,
  setSelectedVariant,
  selectedVariant,
  discountedAmount,
  discount,
  hidePrice,
  testId
}: VariantSelectorComponentProps) => {
  return (
    <div>
      <label htmlFor='variant' className='no-margins'>
        <span className='sr-only'>
          {variants[0].selectedOptions.map(opt => opt.name).join(' - ')}
        </span>
        <VariantSelectInput
          name='variant'
          className='variant-select-input'
          onChange={(e) => {
            const variant = variants.find(v => v.id === e.target.value);
            if (!variant) return;
            setSelectedVariant(variant);
          }}
          value={selectedVariant.id}
          data-testid={testId}
        >
          {variants.map((variant: ShopifyProductVariant) => {
            const discountedPrice = discountedAmount(variant, discount, true);

            return (
              <option
                value={variant.id}
                data-in-stock={variant.availableForSale}
                key={variant.id}
              >
                {variant.title}
                {!hidePrice && `
                  -
                  $${Number(variant.price.amount).toFixed(2)}
                  ${Number(discountedPrice) > 0 ? `($${discountedPrice})` : ''}
                `}
              </option>
            )
          })}
        </VariantSelectInput>
      </label>
    </div>
  )
}

const VariantSelectorButtons = ({
  variants,
  setSelectedVariant,
  selectedVariant,
  discountedAmount,
  discount,
  hidePrice,
  testId,
  defaultTitle,
  size = 'normal',
}: VariantSelectorComponentProps) => {
  const generateClasses = (variant: ShopifyProductVariant) => {
    const classes: string[] = [];

    if (selectedVariant.sku === variant.sku) {
      classes.push('active');
    }

    if (variants.length === 1) {
      classes.push('product-single-variant');
    }

    return classes.join(" ");
  }

  return (
    <VariantGrid size={size} cols={variants.length} style={{ padding: '3rem 0' }} data-testid={testId}>
      {variants.map((variant: ShopifyProductVariant) => (
        <CardButton
          data-testid="product-variant"
          data-in-stock={variant.availableForSale}
          key={variant.id}
          className={generateClasses(variant)}
          hasVariants={variants.length > 1}
          onClick={() => setSelectedVariant(variant)}
          size={size}
        >
          <p className="no-margins variant-text">
            {defaultTitle || variant.title}
          </p>
          {!hidePrice && (
            <Price
              basePrice={getBasePrice(variant)}
              discountAmount={Number(discountedAmount(variant, discount))}
              type={size === 'small' ? 'tiny' : 'product'}
            />
          )}
        </CardButton>
      ))}
    </VariantGrid>
  )
}

// Used for Shopify product variants
export function VariantSelector({
  variants,
  setSelectedVariant,
  selectedVariant,
  defaultTitle,
  discount,
  size = 'normal',
  testId = 'variants',
  type = 'buttons',
  hidePrice = false,
  variantDescription
}: Props) {
  const { getDiscountAmount, getDiscountedPrice } = useDiscount();

  const discountedAmount = (
    variant: ShopifyProductVariant,
    discount?: { amount: number, percentage: number },
    returnPrice?: boolean,
  ) => {
    if (variant.compareAtPrice) {
      return Number(variant.compareAtPrice.amount) - Number(variant.price.amount)
    }

    if (typeof discount?.amount !== 'undefined') {
      return discount.amount;
    }

    if (typeof discount?.percentage !== 'undefined') {
      return Number(variant.price.amount) * (discount.percentage / 100)
    }

    if (returnPrice) {
      const discountAmount = getDiscountAmount(variant);

      if (discountAmount === 0) return 0;

      return getDiscountedPrice({
        price: Number(variant.price.amount),
        discountAmount
      })
    }

    return getDiscountAmount(variant)
  }

  return (
    <VariantSelectorContainer className='variant-selector-container'>
      {variantDescription && <p className="no-margins"><strong>{variantDescription}</strong></p>}
      {
        type === 'input' && variants.length > 1
        ? (
          <VariantSelectorInput
            variants={variants}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            testId={testId}
            variantDescription={variantDescription}
            discountedAmount={discountedAmount}
            discount={discount}
            hidePrice={hidePrice}
          />
        )
        : (
          <VariantSelectorButtons
            variants={variants}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            testId={testId}
            variantDescription={variantDescription}
            discountedAmount={discountedAmount}
            discount={discount}
            hidePrice={hidePrice}
            size={size}
            defaultTitle={defaultTitle}
          />
        )
      }
    </VariantSelectorContainer>
  )
}

const VariantSelectorContainer =styled.div`
  font-family: var(--headlineFont);
`

const VariantSelectInput = styled.select`
  border-radius: 25px;
  padding: 12px 30px 12px 20px;
  margin-bottom: 30px;
`

export const VariantGrid = styled(Grid) <{ size: 'normal' | 'small' }>`
  ${({ size }: { size: 'normal' | 'small' }) => css`
    padding: ${size === 'small' ? '.7rem 0px !important' : '3rem 0px'};
  `}

  grid-gap: 1.5rem;
  ${below.xSmall`
    grid-template-columns: repeat(1,1fr);
  `}
`

export const CardButton = styled.button<{ hasVariants: boolean, size: 'normal' | 'small' }>`
  color: var(--primaryColor);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: var(--cardRadius);
  transition: var(--transitionSpeed) ease border-color;
  @media (min-width: 500px) {
    .divider {
      &:after {
        content: "|";
        margin: 0 15px;
      }
    }
  }
  @media (max-width: 499px) {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    .variant-text {
      width: 100%;
    }
  }

  ${({ hasVariants, size }: { hasVariants: boolean, size: 'normal' | 'small' }) =>
    hasVariants
      ? css`
          padding: ${size === 'small' ? '0' : '1.5rem'};
          border: 1px solid var(--lineColor);
          &:focus, &.active:focus {
            border: 2px solid var(--primaryColor);
          }
          &.active {
            background: white;
            border-color: var(--lightGray);
          }
        `
      : css`
          padding: 1rem 2rem;
          font-size: var(--largestFontSize);
          border: 1px solid var(--lineColor);
          border-radius: var(--cardRadius);
          cursor: auto;
          .variant-text {
            margin: 0 auto;
            max-width: 380px;
            font-size: 1.8rem;
          }
        `};
`